import CommunityBody from './components/CommunityBody';

function Community() {
  return (
    <>
      <CommunityBody />
    </>
  );
}

export default Community;
